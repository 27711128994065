import * as React from "react";

// Components
import Layout from "../../components/Layouts/Layouts";
import Seo from "../../components/Layouts/Seo";
import Banner from "../../components/Content/Banner/Index";
import LeadIn from "../../components/Content/LeadIn/Index";
import FeatureCaseStudy from "../../components/Content/FeatureArticle/Dynamic";
import FeatureApplication from "../../components/Content/FeatureApplication/Index";
import Col2 from "../../components/Content/TextMedia/Col-2";
import Col1 from "../../components/Content/TextMedia/Col-1";
import Video from "../../components/Content/Video/Index";
import TextIcon from "../../components/Content/TextIcon/Index";

// Assets
import brandLogo from "../../assets/logos/logo-maxipro.svg";
import bannerImage from "../../assets/images/banner/banner-maxipro.jpg";
import installationVideo from "../../assets/images/products/landing/video-installation-maxipro.jpg";
import brandBmaxipro from "../../assets/logos/logo-maxipro.svg";
import iconSearch from "../../assets/icons/icon-search.svg";
import hnbr from "../../assets/images/products/landing/refrigeration-airconditioning-o.png";
import diagram from "../../assets/images/products/landing/three-point-press-copper.jpg";

// data
import data from "../static-pages/landing/maxipro.yaml";
import { UseBlogData } from "../../helpers/hooks/BlogQuery";

// markup
const IndexPage: React.FC = () => {
  const featured = UseBlogData();

  return (
    <Layout>
      <Seo
        title=">B< Press MaxiPro"
        description=" >B< MaxiPro is an innovative press fitting system that provides a secure, permanent and leak-proof joint suitable for both refrigeration and air conditioning applications. "
      />
      <Banner
        type="product"
        title="Fitting System"
        summary='1/4" - 1 3/8"'
        divider={true}
        brandMediaUrl={brandLogo}
        brandMediaWidth="400px"
        brandMediaTitle="Placeholder"
        featureMediaUrl={bannerImage}
        featureMediaTitle="Placeholder"
        removePadding={true}
      />
      <LeadIn summary=">B< MaxiPro is an innovative press fitting system that provides a secure, permanent and leak-proof joint suitable for both refrigeration and air conditioning applications. " />
      <FeatureApplication
        sectionId="1"
        section="muted"
        heading="Applications"
        content=">B< MaxiPro is suitable for use with a variety of different refrigerants and other mediums. To find out more, download our refrigerant compatibility table."
        divider={true}
        buttonValue="Download Refrigerant Compatibility"
        buttonUrl="/downloads/b-maxipro/BMaxiPro Refrigerant Compatibility 19-05-23.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        data={data.tabs}
      />
      <LeadIn
        section="muted"
        brandHeading="Features"
        paddingSize="small"
        divider={true}
      />
      <Col2
        section="muted"
        summary="
          <h2>O-Ring</h2>
          <p>>B< MaxiPro fittings are identified by a pink marking on the body. The high quality HNBR O-ring has been specially formulated to operate in the temperature range of -40°C to 121°C.</p>
        "
        backgroundMediaUrl={hnbr}
        backgroundMediaTitle="Refrigeration and air conditionging"
        mediaPositionClass="right"
        removePadding={true}
      />
      <Col1
        section="muted"
        heading="3-Point Press"
        summary="The >B< profile has the advantage of two mechanical presses either side of the bead and one hydraulic press crimping the O-ring. This provides a permanent and secure joint. The lead-in edge design aids installation and helps protect the O-ring from damage or displacement."
        backgroundMediaUrl={diagram}
        backgroundMediaTitle="piping diagram"
        buttonValue="View Range"
        buttonUrl="/products?brandTitle=>B<+MaxiPro"
        buttonBottom={true}
        removePadding={true}
      />
      <LeadIn
        brandHeading="How To Install"
        subHeading='1/4" - 1 1/8"'
        buttonValue="Download Installation Guide"
        buttonUrl="/downloads/b-maxipro/B-MaxiPro Installation Guide.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        divider={true}
      >
        <Video
          margin={true}
          videoMediaType="youtube"
          videoMediaId="HTnChi3Txck"
          backgroundMediaUrl={installationVideo}
          backgroundMediaTitle="Installation"
          backgroundMediaWidth="800px"
        />
      </LeadIn>
      <LeadIn
        section="default"
        subHeading="Why choose"
        brandMediaUrl={brandBmaxipro}
        brandMediaWidth="280px"
        brandMediaTitle="Placeholder"
        buttonValue="Download Technical Guide"
        buttonUrl="/downloads/b-maxipro/B-MaxiPro-Technical-Guide.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        divider={true}
        paddingSize="small"
      >
        <TextIcon data={data.facts} />
      </LeadIn>
      <LeadIn
        section="muted"
        subHeading="15 year"
        brandHeading="Warranty"
        divider={true}
        summary="A 15 year warranty covers against faults caused by defective manufacture of the fittings. It does not cover faults arising from incorrect installation."
        content="All >B< MaxiPro fittings must be installed by a tradesperson licensed to work on systems containing refrigerant. "
        buttonValue="Download Warranty"
        buttonUrl="/downloads/b-maxipro/B-MaxiPro Fittings Warranty.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
      />
      <FeatureCaseStudy paddingSize="large" data={featured.bmaxipro} />
      <LeadIn
        section="primary"
        brandMediaUrl={iconSearch}
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Need help finding the right system for the job?"
        paddingSize="small"
        buttonValue="Launch Product Selector"
        buttonUrl="#js-product-selector-app"
        buttonModal={true}
      />
    </Layout>
  );
};

export default IndexPage;
